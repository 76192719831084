// 登录注册
import request from "@/utils/request.js"

// 注册验证码
export const regSendCode = (phone) => {
  return request({
    method: 'POST',
    url: '/regsendcaptcha',
    data: {
      phone
    }
  })
}
// 注册
export const register = (data) => {
  return request({
    method: 'post',
    url: '/register',
    data
  })
}
// 登录验证码
export const loginSendCode = (phone) => {
  return request({
    method: 'POST',
    url: '/logsendcaptcha',
    data: {
      phone
    }
  })
}
// 登录
export const login = (data) => {
  return request({
    method: 'post',
    url: '/login',
    data
  })
}

// 退出登录
export const logout = () => {
  return request({
    method: 'get',
    url: '/logout',
    headers: {
      token: true
    }
  })
}