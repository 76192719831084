import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import echarts from './plugins/echarts'
import elementui from './plugins/elementUi'
import { Loading } from 'element-ui';
import { message } from './utils/reset-message' // reset message
import CountTo from "vue-count-to"//数字滚动
import { settleHtml } from './utils/index'
import rules from './utils/formCheck.js'
import './assets/css/reset.scss'
import './assets/css/common.scss'
import './assets/css/common-media.scss'
import './assets/css/elCommon.scss'
import infiniteScroll from "vue-infinite-scroll";
import elTableInfiniteScroll from 'el-table-infinite-scroll';


// 时间戳转化
import * as moment from '@/utils/filter'

Vue.config.productionTip = false
Vue.use(infiniteScroll);
Vue.use(elementui)
Vue.use(elTableInfiniteScroll);
Vue.prototype.$message = message
Vue.prototype.$echarts = echarts
Vue.prototype.$rules = rules
Vue.prototype.$settleHtml = settleHtml
Vue.use(Loading.directive);

Vue.component('count-to', CountTo)


// 图片地址
Vue.prototype.$base = process.env.VUE_APP_IMAGE_URL
Vue.prototype.$isPc = (/(iPhone|iPod|iOS|Android)/i.test(navigator.userAgent)) ? false : true
// 百度商桥咨询链接
Vue.prototype.$zxlj = 'https://affim.baidu.com/unique_31173494/chat?siteId=19550814&userId=31173494&siteToken=ec1c4c00c91d75fd63e3e538b6418d08'

// 在线咨询
Vue.prototype.consultingClick = function () {
  window.open(Vue.prototype.$zxlj, 'windowForBridgeIM',
    'left=100,top=200,width=800,height=660,location=no,resizable=yes,status=no,toolbar=no,menubar=no')
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
