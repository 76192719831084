<template>
  <div id="app">
    <template v-if="this.$store.getters.configs">
      <Layout />
      <Consulting v-show="!($route.matched.some(record => record.meta.hideBackTop))" />
    </template>
    <template v-else>
      <div v-loading.fullscreen.lock="!this.$store.getters.configs"> </div>
    </template>
  </div>
</template>
<script>
import { getConfig } from '@/api/index'
import Layout from './views/Layout/index.vue'
import Consulting from '@/components/Consulting'
export default {
  components: {
    Layout,
    Consulting
  },
  mounted () {
    this.getConfig()
  },
  methods: {
    // 获取网站配置
    async getConfig () {
      let { data } = await getConfig()
      data['hotline_phone'] = '022-58209880'
      this.$store.dispatch('setConfigs', data)
    },
  },
}
</script>