import Vue from 'vue'
import Vuex from 'vuex'
import {
  setItem,
  getItem
} from '@/utils/storage'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userToken: getItem('USER_TOKEN') || '',
    userInfo: getItem('USER_INFO') || null,
    configs: null, // 网站配置
    showApply: false, //金融产品申请框
    isTradingListReset: false,// 卖出管理列表重置
    dataToken: getItem('DATA_TOKEN', 'sessionStorage') || '', //数据大屏登录token
    dataCondition: getItem('DATA_CONDITION', 'sessionStorage') || {
      nav: 'pledge',
      year: new Date().getFullYear(),
      map: { name: "天津市", code: 120000 }
    }, //数据大屏数据展示的条件
    dataAreaDetails: {},//数据大屏地区数据详情
  },
  mutations: {
    SET_USER_TOKEN (state, value) {
      state.userToken = value
      setItem('USER_TOKEN', value)
      state.showAccount = false
      if (!value) {
        state.userInfo = null
        setItem('USER_INFO', {})
      }
    },
    SET_USER_INFO (state, value) {
      state.userInfo = value
      setItem('USER_INFO', value)
    },
    SET_CONFIGS (state, payload) {
      state.configs = payload
    },
    SET_SHOW_APPLY (state, value) {
      state.showApply = value
    },
    SET_IS_TRADING_LIST_RESET (state, value) {
      state.isTradingListReset = value
    },
    SET_DATA_TOKEN (state, value) {
      state.dataToken = value
      setItem('DATA_TOKEN', value, 'sessionStorage')
    },
    SET_DATA_CONDITION (state, value) {
      state.dataCondition = value
      setItem('DATA_CONDITION', value, 'sessionStorage')
    },
    SET_DATA_ARER_DETAILS (state, value) {
      state.dataAreaDetails = value
    },
  },
  actions: {
    // 存储用户token
    setUserToken (context, val) {
      context.commit('SET_USER_TOKEN', val)
    },
    // 存储用户信息
    setUserInfo (context, val) {
      context.commit('SET_USER_INFO', val)
    },
    setConfigs (context, val) {
      context.commit('SET_CONFIGS', val)
    },
    // 产品申请表单
    setShowApply (context, val) {
      context.commit('SET_SHOW_APPLY', val)
    },
    // 卖出管理列表重置
    setIsTradingListReset (context, val) {
      context.commit('SET_IS_TRADING_LIST_RESET', val)
    },
    // 存储数据大屏用户token
    setDataToken (context, val) {
      context.commit('SET_DATA_TOKEN', val)
    },
    setDataCondition (context, val) {
      context.commit('SET_DATA_CONDITION', val)
    },
    // 存储数据大屏地区数据详情
    setDataAreaDetails (context, val) {
      context.commit('SET_DATA_ARER_DETAILS', val)
    },
  },
  getters: {
    userToken: state => state.userToken,
    userInfo: state => state.userInfo,
    configs: state => state.configs,
    showApply: state => state.showApply,
    isTradingListReset: state => state.isTradingListReset,
    dataToken: state => state.dataToken,
    dataCondition: state => state.dataCondition,
    dataAreaDetails: state => state.dataAreaDetails,
  },
  modules: {}
})