import Vue from 'vue'

Vue.filter('filterTime', function (time) {
  /**
   * 在ios上识别不出“-” 会输出NaN
   * 需使用replace(/-/g, '/') 把“-”变为“/”
   */
  if (!time) {
    return false
  }
  time = new Date(time.replace(/-/g, '/'))
  var year = time.getFullYear()
  var month = time.getMonth() + 1
  var day = time.getDate()
  var hour = time.getHours()
  var minute = time.getMinutes()
  var second = time.getSeconds()
  month = month < 10 ? '0' + month : month
  day = day < 10 ? '0' + day : day
  hour = hour < 10 ? '0' + hour : hour
  minute = minute < 10 ? '0' + minute : minute
  second = second < 10 ? '0' + second : second
  time = `${year}-${month}-${day}`
  return time
})

Vue.filter('filterStr', function (str) {
  /**
   * 隐藏公司名称
   * 中间文字替换为*
   */
  var newStr = str
  if (str.length > 6) {
    newStr = str.substr(0, 2) + "****" + str.substr(str.length - 4)
  }
  return newStr
})
