/** 头部 */
<template>
  <div class="my-header back-fff">
    <div v-if="$isPc || $route.matched.some((record) => record.meta.isPcHead)"
      class="content align-center justify-between">
      <section class="align-center flex-1">
        <router-link to="/">
          <el-image class="logo mr-20" :src="require($isPc
            ? '@/assets/images/logo.png'
            : '@/assets/images/logo2.png')
            " fit="contain">
          </el-image>
        </router-link>
        <el-menu v-show="$isPc && !$route.matched.some((record) => record.meta.hideNav)
          " mode="horizontal" :default-active="activeMenu" class="flex-1 el-menu-demo ml-20" background-color="#FFFFFF"
          text-color="#7F8AA4" active-text-color="#347EED" router>
          <template v-for="(nav, navIndex) in navs">
            <el-submenu v-if="nav.children && nav.children.length" :key="nav.route_url" :index="nav.route_url">
              <template slot="title">{{ nav.name }}</template>
              <el-menu-item v-for="(item, index) in nav.children" :key="index" :index="item.route_url">
                {{ item.name }}
              </el-menu-item>
            </el-submenu>
            <el-menu-item v-else :key="navIndex" :index="nav.route_url">
              {{ nav.name }}
            </el-menu-item>
          </template>
        </el-menu>
      </section>
      <section>
        <!-- 未登录 -->
        <div v-if="!userToken" class="login-btns">
          <el-button type="text">
            <router-link to="/account/login" replace>登录</router-link>
          </el-button>
          <el-button type="text">
            <router-link to="/account/register" replace>注册</router-link>
          </el-button>
        </div>
        <!-- 已登录 -->
        <div v-else class="user-info">
          <el-dropdown ref="userDrop" class="ml-22" trigger="click" :hide-on-click="false" @command="handleCommand">
            <span class="el-dropdown-link">
              {{ userInfo.username
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown" class="user-info-dropdown">
              <el-dropdown-item command="my"> 个人中心 </el-dropdown-item>
              <el-dropdown-item command="logout">
                <el-popconfirm title="确定退出登录吗？" @confirm="logout">
                  <span slot="reference"> 退出登录 </span>
                </el-popconfirm>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </section>
    </div>
    <div v-else class="content align-center">
      <el-page-header class="el-icon-arrow-left" @back="goBack" :content="$route.meta.detailsTitle">
      </el-page-header>
    </div>
  </div>
</template>
<script>
import { logout } from '@/api/account'
import { mapGetters } from 'vuex'
import Search from '@/components/Search.vue'
export default {
  name: 'Header',
  components: {
    Search,
  },
  data () {
    return {
      navs: [
        { name: '首页', route_url: '/home' },
        { name: '金融超市', route_url: '/serve/product' },
        { name: '政策服务', route_url: '/policy' },
        { name: '新闻中心', route_url: '/news' },
        {
          name: '活动专场',
          route_url: '/activity',
          children: [
            { name: '活动通知', route_url: '/activity/events', },
            { name: '活动新闻', route_url: '/activity/news', },
          ],
        },
        {
          name: '知识产权供需',
          route_url: '/trading',
          children: [
            { name: '开放许可', route_url: '/trading/license' },
            { name: '专利交易', route_url: '/trading/patent' },
            { name: '商标交易', route_url: '/trading/trademark' },
          ],
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['userToken', 'userInfo']),
    activeMenu () {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
  },
  watch: {
    userToken (newVal) {
      if (
        !newVal &&
        this.$route.matched.some((record) => record.meta.loginAuth)
      ) {
        this.$router.replace('/')
      }
    },
    userInfo: {
      handler: function () {
        this.setNavs()
      },
    },
  },
  mounted () {
    this.setNavs()
  },
  methods: {
    setNavs () {
      let ind = this.navs.findIndex((i) => i.route_url === '/data_service')
      if (ind == -1 && this.userInfo && this.userInfo.is_scress == 2) {
        this.navs.push({
          name: '数据服务',
          route_url: '/data_service',
          target: '_blank',
        })
      } else if (ind !== -1) {
        this.navs.splice(ind, 1)
      }
    },
    // 登录成功后切换
    async handleCommand (command) {
      switch (command) {
        case 'my':
          this.$router.push('/my')
          this.$refs.userDrop.hide()
          break
        default:
          break
      }
    },
    async logout () {
      await logout()
      this.$store.dispatch('setUserToken', '')
    },
    // 返回上一页
    goBack () {
      this.$router.go(-1) //返回上一层
    },
  },
}
</script>
<style lang="scss" scoped>
.my-header {
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  box-shadow: 0px 0px 7px 0px rgba(60, 79, 141, 0.2);
}

/* 导航 */
::v-deep .el-menu.el-menu--horizontal {
  border: none;

  .el-menu-item,
  .el-submenu__title {
    height: 45px;
    line-height: 45px;
    padding: 0;
    margin: 0 30px;
    border: none !important;

    &:hover {
      background-color: transparent !important;
    }
  }

  .el-menu-item,
  .el-submenu {
    &.is-active {
      font-weight: bold;
      border: none;
    }
  }
}

@media (max-width:1399px) {
  .el-menu.el-menu--horizontal {

    .el-menu-item,
    .el-submenu__title {
      margin: 0 20px !important;
    }
  }
}
@media (max-width: 767px) {
  .my-header {
    height: 60px;
    background-color: #2b62b2;

    .logo {
      height: 40px;
      margin-left: 10px;
    }

    .user-info,
    .login-btns {
      margin-right: 10px;

      .el-dropdown-link,
      a {
        color: #fff;
      }
    }
  }

  ::v-deep .el-page-header {
    width: 100%;
    padding: 0 10px;
    color: #fff;

    .el-page-header__left {
      margin-right: 0;

      &::after {
        display: none;
      }

      &>i {
        display: none;
      }
    }

    .el-page-header__content {
      flex: 1;
      font-size: 14px;
      color: #fff;
      text-align: center;
      margin-left: -52px;
    }
  }
}
</style>
<style lang="scss">
.el-menu--horizontal {
  .el-menu--popup {
    min-width: 140px;
  }
}
</style>
