<template>
  <div class="search">
    <el-input :class="{ 'inp-error': isSearchError }" :style="{ backgroundColor: backColor }" v-model="searchVal"
      clearable @input="isSearchError = false" @change="changeAll" @keyup.enter.native="searchBtn" placeholder="请输入关键词搜索"
      prefix-icon="el-icon-search">
      <el-button slot="append" type="primary" :loading="searchLoading" @click="searchBtn">{{ btnText }}</el-button>
    </el-input>
  </div>
</template>
<script>
export default {
  name: 'Search',
  props: {
    backColor: {
      typeof: String,
      default: '#EFF3FA'
    },
    btnText: {
      typeof: String,
      default: '搜 索'
    },
    searchLoading: {
      typeof: Boolean,
      default: false
    }
  },
  data () {
    return {
      searchVal: '', // 搜索内容
      isSearchError: false,//是否展示搜索错误无样式
      isSearch: false,
    }
  },
  methods: {
    changeAll () {
      if (!this.searchVal && this.isSearch) {
        this.isSearch = false
        this.$emit('searchBtn', this.searchVal)
      }
    },
    searchBtn () {
      if (this.searchVal) {
        this.isSearch = true
        this.$emit('searchBtn', this.searchVal)
      } else {
        this.isSearchError = true
      }
    }
  },
}
</script>
<style lang="scss" scoped>
/deep/ .el-input {
  border-radius: 4px;

  .el-input__inner {
    background-color: transparent;
    border: none;
  }

  .el-input-group__append {
    border: none;

    .el-button {
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      background-color: #409eff;

      &:hover {
        background-color: #66b1ff;
      }
    }
  }
}

@media (max-width: 767px) {
  .search {
    padding: 0 10px;

    .el-input {
      .el-button {
        font-size: 14px;
      }
    }
  }
}
</style>