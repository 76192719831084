// 处理html字符
export const settleHtml = (string) => {
  if (string) {
    let start = string.indexOf('<')
    let end = string.indexOf('>')
    let data = string.slice(start, end + 1)
    string = string.replace(data, '')
    string = string.replace(' ', '')
    if (start != -1 && end != -1) {
      return settleHtml(string)
    } else {
      return string.replace(/\s*/g, '')
    }
  }
}
// 防抖
export const debounce = (fn, gapTime) => {
  let _lastTime
  return function () {
    clearTimeout(_lastTime)
    _lastTime = setTimeout(() => {
      fn.apply(this, arguments)
    }, gapTime)
  }
}
//js解决数字相加精度问题
export const numAdd = (num1, num2) => {
  let baseNum, baseNum1, baseNum2
  try {
    baseNum1 = num1.toString().split('.')[1].length
  } catch (e) {
    baseNum1 = 0
  }
  try {
    baseNum2 = num2.toString().split('.')[1].length
  } catch (e) {
    baseNum2 = 0
  }
  baseNum = Math.pow(10, Math.max(baseNum1, baseNum2))
  let precision = baseNum1 >= baseNum2 ? baseNum1 : baseNum2 //精度
  return ((num1 * baseNum + num2 * baseNum) / baseNum).toFixed(precision)
}
