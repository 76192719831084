import request from "@/utils/request.js"

// 获取文章
export const getNews = (data) => {
  return request({
    method: 'get',
    url: '/getnews',
    headers: {
      isStop: true
    },
    params: data,
  })
}

// 文章详情
export const getNewsDetails = (id) => {
  return request({
    method: 'get',
    url: '/getnewsinfo',
    params: { id }
  })
}
