/** 首页 */
<template>
  <div class="home mb-30">
    <!-- banner图 -->
    <section class="home-banner mb-20">
      <swiper v-if="banners && banners.length" ref="mySwiper" :options="swiperOptionsFun()">
        <swiper-slide v-for="(item,index) in banners" :key="index">
          <img :src="$base+item.value" :alt="item.group_name" />
        </swiper-slide>
      </swiper>
    </section>
    <!-- 导航 -->
    <section v-if="!$isPc" class="home-navs plr-10">
      <el-row :gutter="20" class="navs-list">
        <el-col :span="8" class="navs-item" v-for="(item,index) in wapNavs" :key="index">
          <router-link :to="item.route_url">
            <el-image :src="item.img" class="navs-img mb-10" fit="contain"></el-image>
            <p class="navs-name">{{item.name}}</p>
          </router-link>
        </el-col>
      </el-row>
    </section>
    <!-- 企业动态 -->
    <section :class="['home-dynamic', 'content',$isPc?'home-dynamic-box mb-30':'']">
      <!-- PC -->
      <template v-if="$isPc">
        <h1 class="dynamic-title">企业动态</h1>
        <div class="dynamic-con flex">
          <template v-if="dynamicApply && dynamicApply.length">
            <swiper ref="mySwiper" :options="dynamicSwiperFun(dynamicApply.length>4)">
              <swiper-slide v-for="(item,index) in dynamicApply" :key="index">
                <el-row type="flex">
                  <el-col :span="8"><span>{{item.company_name|filterStr}}</span></el-col>
                  <el-col :span="5"><span>申请融资</span></el-col>
                  <el-col :span="6"><strong>{{item.credit_line}}万</strong></el-col>
                  <el-col :span="5"><span>{{item.created_at}}</span></el-col>
                </el-row>
              </swiper-slide>
            </swiper>
          </template>
          <el-divider direction="vertical"></el-divider>
          <template v-if="dynamicDocked && dynamicDocked.length">
            <swiper ref="mySwiper" :options="dynamicSwiperFun(dynamicDocked.length>3)">
              <swiper-slide v-for="(item,index) in dynamicDocked" :key="index">
                <el-row type="flex">
                  <el-col :span="8"><span>{{item.company_name|filterStr}}</span></el-col>
                  <el-col :span="5"><span>对接成功</span></el-col>
                  <el-col :span="6" class="text-ellipsis"><span>{{item.institution_name}}</span></el-col>
                  <el-col :span="5"><span>{{item.created_at}}</span></el-col>
                </el-row>
              </swiper-slide>
            </swiper>
          </template>
        </div>
      </template>
      <!-- 移动端 -->
      <template v-else>
        <div class="ipbs-title">
          <h4>企业动态</h4>
        </div>
        <div v-if="dynamicAll && dynamicAll.length" class="dynamic-con dynamic-con-mobile flex plr-10">
          <swiper ref="mySwiper" :options="dynamicSwiperFun(dynamicAll.length>3)">
            <swiper-slide v-for="(item,index) in dynamicAll" :key="index">
              <el-row type="flex">
                <el-col :xs="9"><span>{{item.company_name|filterStr}}</span></el-col>
                <el-col :xs="5"><span>{{item.type=='apply'?'申请融资':'对接成功'}}</span></el-col>
                <el-col :xs="7" class="text-ellipsis">
                  <strong>{{item.type=='apply'?item.credit_line+'万':item.institution_name}}</strong>
                </el-col>
                <el-col :xs="1" class="flex-1"><span>{{item.created_at}}</span></el-col>
              </el-row>
            </swiper-slide>
          </swiper>
        </div>
      </template>
    </section>
    <!-- 金融产品 -->
    <section class="home-fp content">
      <div class="ipbs-title">
        <h4>金融产品</h4>
        <el-button v-if="!$isPc" type='text' @click="$router.push('/serve/product')">
          查看更多<i class="el-icon-arrow-right el-icon--right"></i>
        </el-button>
      </div>
      <el-row v-if="product.length" type="flex" :gutter="$isPc?20:0" :class="['fp-list','flex-wrap',{'plr-10':!$isPc}]">
        <el-col :xs='12' :sm="8" :md="6" v-for="(item,index) in product" :key="index" class="fp-item">
          <div class="product-info">
            <div :class="[$isPc?'ptb-30':'pa-10']">
              <div :class="['product-img', 'flex-center',$isPc?'mb-30':'mb-10' ]">
                <el-image :src="$base+item.product_logo" fit="contain"> </el-image>
              </div>
              <p class="product-name flex-center"><strong>{{item.product_name}}</strong></p>
            </div>
            <div class="product-btn justify-around align-center">
              <router-link :to="{ path: '/product_details', query: { id: item.id } }">
                <el-button type="primary">了解详情 </el-button>
              </router-link>
            </div>
          </div>
        </el-col>
        <el-col v-if="$isPc && product.length>=7" :xs='12' :sm="8" :md="6" class="fp-item">
          <router-link to="/serve/product" class="product-info flex-center" style="background-color:#f6faff">
            <p class="product-name"> 查看更多 </p>
          </router-link>
        </el-col>
      </el-row>
      <div v-else v-loading="fpLoading" class="loading-con"></div>
    </section>
    <!-- 服务政策 -->
    <section class="home-yb content mb-30">
      <div class="ipbs-title">
        <h4>服务政策</h4>
        <el-button v-if="!$isPc" type='text' @click="$router.push('/policy')">
          查看更多<i class="el-icon-arrow-right el-icon--right"></i>
        </el-button>
      </div>
      <template v-if="ybData.length">
        <el-row type="flex" :class="['yb-con',{'plr-10':!$isPc}]">
          <el-col v-if="$isPc" :span="12" class="yb-img">
            <el-image class="flex-1" :src="require('@/assets/images/home-yb1.png')" fit="cover"> </el-image>
          </el-col>
          <el-col :span="$isPc?12:24" class="flex-1">
            <ul class="yb-list flex-wrap flex-column justify-between">
              <li class="yb-item back-fff border-r4 flex" v-for="(item,index) in ybData" :key="index">
                <div :class="['yb-item-info', {'flex-1':$isPc}, 'justify-between']">
                  <h4 class="yb-item-title">
                    <router-link :to="{ path: '/policy_details', query: { id: item.id } }" class="text-ellipsis">
                      {{item.title}}
                    </router-link>
                  </h4>
                  <span class="item-default">{{item.release_at|filterTime}}</span>
                </div>
              </li>
            </ul>
          </el-col>
        </el-row>
        <el-button v-if="$isPc" type="info" class="ipbs-more">
          <router-link to="/policy">查看更多</router-link>
        </el-button>
      </template>
      <div v-else v-loading="ybLoading" class="loading-con"></div>
    </section>
    <!-- 新闻中心 -->
    <section class="home-news content mb-30">
      <div class="ipbs-title">
        <h4>新闻中心</h4>
        <el-button v-if="!$isPc" type='text' @click="$router.push('/news')">
          查看更多<i class="el-icon-arrow-right el-icon--right"></i>
        </el-button>
      </div>
      <template v-if="newsData.length">
        <el-row type="flex" :class="['yb-con',{'plr-10':!$isPc}]">
          <el-col :span="$isPc?12:24" class="flex-1">
            <ul class="yb-list flex-wrap flex-column justify-between">
              <li class="yb-item back-fff border-r4 flex" v-for="(item,index) in newsData" :key="index">
                <div :class="['yb-item-info', {'flex-1':$isPc}, 'justify-between']">
                  <h4 class="yb-item-title">
                    <router-link :to="{ path: '/news_details', query: { id: item.id } }" class="text-ellipsis">
                      {{item.title}}
                    </router-link>
                  </h4>
                  <span class="item-default">{{item.release_at|filterTime}}</span>
                </div>
              </li>
            </ul>
          </el-col>
          <el-col v-if="$isPc" :span="12" class="yb-img">
            <el-image class="flex-1" :src="require('@/assets/images/home-news1.png')" fit="cover"> </el-image>
          </el-col>
        </el-row>
        <el-button v-if="$isPc" type="info" class="ipbs-more">
          <router-link to="/news">查看更多</router-link>
        </el-button>
      </template>
      <div v-else v-loading="newsLoading" class="loading-con"></div>
    </section>
    <!-- 活动专场 -->
    <section class="home-activity">
      <div class="content">
        <div class="ipbs-title">
          <h4>活动专场</h4>
          <el-button v-if="!$isPc" type='text' @click="$router.push(activityType=='events'?'/activity_list':'/activity/news')">
            查看更多<i class="el-icon-arrow-right el-icon--right"></i>
          </el-button>
        </div>
        <ul class="activity-tabs mb-30 flex-center">
            <li :class="['activity-tab',{'active':activityType=='events'}]" @click="activityType='events'">活动通知</li>
            <li :class="['activity-tab',{'active':activityType=='news'}]" @click="activityType='news'">活动新闻</li>
          </ul>
        <template v-if="activity.length || activityNews.length">
          <el-row v-if="activityType=='events'" :gutter="$isPc?20:0" :class="['activity-list', {'plr-10':!$isPc}]">
            <el-col :sm="24" :md="12" class="activity-item mb-20" v-for="(item,index) in activity" :key="index">
              <div class="activity-item-con flex back-fff">
                <el-image class="activity-img flex-1" :src="$base+item.logo" fit="fill">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
                <div class="activity-info flex-1">
                  <h4 class="activity-title text-ellipsis mb-20">{{item.title}}</h4>
                  <el-row class="activity-info-list">
                    <el-col class="activity-info-item text-ellipsis">
                      <el-image :src="require('@/assets/images/home-activity1.png')"></el-image>
                      {{item.unit}}
                    </el-col>
                    <el-col :span="17" class="activity-info-item text-ellipsis">
                      <el-image :src="require('@/assets/images/home-activity2.png')"></el-image>
                      {{item.address}}
                    </el-col>
                    <el-col class="activity-info-item text-ellipsis">
                      <el-image :src="require('@/assets/images/home-activity3.png')"></el-image>
                      {{item.release_at}}
                    </el-col>
                  </el-row>
                  <div :class="[$isPc?'activity-details-btn':'activity-details-text']">
                    <router-link :to="{path:'/activity_details',query:{id:item.id}}">
                      <el-button type="primary"> 了解详情 </el-button>
                    </router-link>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row v-else :gutter="$isPc?20:0" :class="['activity-list', {'plr-10':!$isPc}]">
            <el-col :sm="24" :md="12" class="activity-item mb-20" v-for="(item,index) in activityNews" :key="index">
              <div class="activity-item-con flex back-fff">
                <el-image class="activity-img flex-1" :src="$base+item.logo" fit="fill">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
                <div class="activity-info flex-1">
                  <h4 class="activity-title text-ellipsis mb-20">{{item.title}}</h4>
                  <el-row class="activity-info-list">
                    <el-col class="activity-info-item">
                      <div v-if="$isPc" class="mb-20 text-ellipsis-lines" style="line-height: 24px;">
                        {{ $settleHtml(item.details) }}
                      </div>
                      <p class="item-info-desc">
                        <span> 发布时间：{{ item.release_at | filterTime }} </span>
                      </p>
                    </el-col>
                  </el-row>
                  <div :class="[$isPc?'activity-details-btn':'activity-details-text']">
                    <router-link :to="{path:'/activity_news_details',query:{id:item.id}}">
                      <el-button type="primary"> 了解详情 </el-button>
                    </router-link>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-button v-if="$isPc" type="info" class="ipbs-more">
            <router-link :to="activityType=='events'?'/activity_list':'/activity/news'">查看更多</router-link>
          </el-button>
        </template>
        <div v-else v-loading="activityLoading" class="loading-con"></div>
      </div>
    </section>
    <!-- 评估服务 -->
    <section class=" mb-30">
      <div v-if="!$isPc" class="ipbs-title">
        <h4>评估服务</h4>
      </div>
      <div class="home-assess">
        <div :class="['content',{'align-center':!$isPc}]">
          <div v-if="$isPc" class="ipbs-title">
            <h4>评估服务</h4>
          </div>
          <div :class="['assess-con', 'align-center', 'back-fff',!$isPc?'flex-wrap justify-center':'']">
            <p class="assess-text">基于涉及政务数据、经营数据、场景交易数据以及工商、司法等公开数据，采用人工智能、区块链、云计算等技术，为金融机构及市场主体提供</p>
            <el-button class="assess-defaults" type="primary" @click="gotoSystem">
              进入系统
              <i class="el-icon-caret-right el-icon--right"></i>
            </el-button>
          </div>
        </div>
      </div>
    </section>
    <!-- 专利评价 -->
    <section v-if="$isPc" class="home-zlpj">
      <div class="ipbs-title">
        <h4>专利评价</h4>
      </div>
      <div class="zlpj-con align-center">
        <div class="zlpj-info flex-1">
          <p class="zlpj-title">在线实时生成的单项专利评估报告、企业整体专利评估报告；区域知识产权分析咨询报告、特定技术领域专利分析评估报告定制的知识产权战略</p>
          <Search class="zlpj-search" btnText="查 询" :searchLoading="searchLoading" @searchBtn="searchBtnZlpj" />
          <p class="zlpj-desc justify-between">
            <span>线上自动评价服务{{zlpjNums}}次</span>
            <span>数据来源：国家专利局</span>
          </p>
        </div>
        <el-image class="zlpj-img" :src="require('@/assets/images/home-zlpj1.png')"></el-image>
      </div>
    </section>
    <!-- 平台优势 -->
    <section class="home-ptys content">
      <div class="ipbs-title">
        <h4>平台优势</h4>
      </div>
      <el-row :gutter="$isPc?34:20" :class="[$isPc?'ptys-list':'ptys-list-mobile']">
        <el-col :xs="24" :sm="8" v-for="(item,index) in ptys" :key="index">
          <div :class="['ptys-item',{'flex':!$isPc}]">
            <div v-if="$isPc?true:!(index%2)" class="ptys-img flex-center">
              <el-image :src="item.img" fit="contain"></el-image>
            </div>
            <div class="ptys-info justify-center align-center flex-column">
              <h4 class="ptys-name">{{item.name}}</h4>
              <i v-if="$isPc" class="ptys-line" :style="{backgroundColor:item.color}"></i>
              <p class="ptys-desc">{{item.desc}}</p>
            </div>
            <div v-if="$isPc?false:!!(index%2)" class="ptys-img flex-center">
              <el-image :src="item.img" fit="contain"> </el-image>
            </div>
          </div>
        </el-col>
      </el-row>
    </section>
    <!-- 合作机构 -->
    <section v-if="$isPc" class="home-hzjg content">
      <div class="ipbs-title">
        <h4>合作机构</h4>
      </div>
      <Partners />
    </section>
    <!-- 友情链接 -->
    <section v-if="$isPc && blogrollList.length" class="home-blogroll content">
      <div class="ipbs-title">
        <h4>友情链接</h4>
      </div>
      <el-row :gutter="20" class="blogroll-list">
        <el-col class="blogroll-item" :sm="6" v-for="(item,index) in blogrollList" :key="index">
          <a :href="item.link" target="_blank">
            <div class="blogroll-img flex-center">
              <el-image :src="$base+item.value"></el-image>
            </div>
            <p class="blogroll-name">{{item.name}}</p>
          </a>
        </el-col>
      </el-row>
    </section>
  </div>
</template>

<script>
import { getDynamic, getNewArticle, getPatentEvaluate } from '@/api/index'
import { getProduct } from '@/api/product'
import { getAllActivity } from '@/api/activity'
import { getNews } from '@/api/news'
import List from '@/components/List.vue'
import Search from '@/components/Search.vue'
import Partners from '@/views/Partners/index.vue'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  name: 'Home',
  components: {
    List,
    Search,
    Partners,
    swiper,
    swiperSlide
  },
  data () {
    return {
      banners: [],
      wapNavs: [
        { name: '找产品', route_url: '/serve/product', img: require('@/assets/images/home-wap1.png') },
        { name: '看政策', route_url: '/policy', img: require('@/assets/images/home-wap2.png') },
        { name: '找活动', route_url: '/activity', img: require('@/assets/images/home-wap3.png') },
      ],
      dynamicAll: [],// 企业动态--全部
      dynamicApply: [],// 企业动态--申请融资
      dynamicDocked: [],// 企业动态--对接成功
      product: [],// 金融产品
      ybData: [],//其他政策
      newsData:[],//新闻中心
      activity: [],// 活动专场
      activityNews: [],// 活动新闻
      activityType:'events',//活动专场切换
      zlpjNums: 0,
      // 平台优势
      ptys: [
        { color: '#347EED', name: '专业', img: require('@/assets/images/home-ptys1.png'), desc: '服务团队拥有十余年知识产权金融工作经验，国内一流专业团队' },
        { color: '#FFB346', name: '权威', img: require('@/assets/images/home-ptys2.png'), desc: '市知识产权局指导运营，推动天津市知识产权金融发展' },
        { color: '#90D06D', name: '便捷', img: require('@/assets/images/home-ptys3.png'), desc: '聚集天津市各类金融机构知识产权融资产品，快速对接落地' },
      ],
      blogrollList: [],// 友情链接
      searchLoading: false,
      fpLoading: true,
      ybLoading: true,
      newsLoading: true,
      activityLoading: true,
    }
  },
  mounted () {
    this.zlpjNums = this.$store.getters.configs.zlpj[0].value * 1
    this.banners = this.$isPc ? this.$store.getters.configs.home_banner : this.$store.getters.configs.mobile_banner
    this.blogrollList = this.$store.getters.configs.blogroll || []
    this.getDynamic()
    this.getProduct()
    this.getNewArticle()
    this.getNewsData()
    this.getAllActivity()
    this.getAllActivityNews()
  },
  methods: {
    // banner轮播
    swiperOptionsFun () {
      return {
        loop: (this.banners.length > 1 ? true : false),
        //自动播放
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,//触摸后再次自动轮播
        },
      }
    },
    // 企业动态滚动
    dynamicSwiperFun (type) {
      return {
        direction: 'vertical',
        loop: type,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,//触摸后再次自动轮播
        },
        slidesPerView: 'auto',//设置slider容器能够同时显示的slides数量
      }
    },
    // 企业动态
    async getDynamic () {
      try {
        let { data } = await getDynamic()
        this.dynamicApply = data.apply || []
        this.dynamicDocked = data.finance || []
        this.dynamicAll = [...this.dynamicApply, ...this.dynamicDocked]
      } catch (error) { }
    },
    // 金融产品
    async getProduct () {
      try {
        let { data } = await getProduct({ show: 2, limit: this.$isPc ? 7 : 4 })
        this.product = data
        this.fpLoading = false
      } catch (error) { }
    },
    // 服务政策
    async getNewArticle () {
      try {
        let { data } = await getNewArticle()
        this.ybData = data
        this.ybLoading = false
      } catch (error) { }
    },
    // 新闻中心
    async getNewsData () {
      try {
        let { data } = await getNews()
        this.newsData = data
        this.newsLoading = false
      } catch (error) { }
    },
    // 活动通知
    async getAllActivity () {
      try {
        let { data } = await getAllActivity({ page: 1, limit: 2 })
        this.activity = data
        this.activityLoading = false
      } catch (error) { }
    },
    // 活动新闻
    async getAllActivityNews () {
      try {
        let { data } = await getAllActivity({ page: 1, limit: 2,type:'活动新闻' })
        this.activityNews = data
        this.activityLoading = false
      } catch (error) { }
    },
    // 评估服务
    async gotoSystem () {
      if (this.$isPc) {
        window.open('http://cloud.cnipol.com')
      }else{
        this.$router.push('/assessment')
      }
    },
    // 专利评价
    async searchBtnZlpj (val) {
      try {
        this.searchLoading = true
        if (!this.$store.getters.userToken) return this.$message.warning('请先登录');
        let { data, code, msg } = await getPatentEvaluate(val)
        if (code == 200 && data) {
          this.$message.success(msg);
          this.zlpjNums += 1
          window.open(this.$base + data)
        } else {
          this.$message.error(msg)
        }
      } catch (error) { }
      this.searchLoading = false
    },
  }
}
</script>
<style lang="scss" scoped>
.el-col {
  a {
    width: 100%;
    height: 100%;
  }
}
// banner图
/deep/ .home-banner {
  img {
    width: 100%;
  }
}
// 导航
.home-navs {
  .navs-list {
    .navs-item {
      text-align: center;
      .navs-img {
        padding: 10px 20px;
        border-radius: 6px;
        background-color: #357eed;
        height: 40px;
      }
    }
  }
}
// 企业动态
.home-dynamic {
  &.home-dynamic-box {
    box-shadow: 0px 0px 13px 0px rgba(59, 95, 192, 0.27);
    border-radius: 10px;
  }
  overflow: hidden;
  .dynamic-title {
    width: 78px;
    line-height: 39px;
    padding: 26px;
    background-color: #1c60cf;
    color: #ffffff;
    float: left;
    text-align: center;
    font-size: 36px;
  }
  .dynamic-con {
    padding: 20px 2%;
    height: 90px;
    overflow: hidden;
    .swiper-container {
      width: 50%;
      margin: 0;
      .swiper-slide {
        height: 30px !important;
        line-height: 30px;
      }
      .el-row {
        padding-left: 20px;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 11px;
          display: inline-block;
          width: 6px;
          height: 6px;
          background-color: #151933;
        }
        .el-col {
          text-align: center;
          &:first-child {
            text-align: left;
          }
          &:last-child {
            text-align: right;
            min-width: 98px;
          }
        }
      }
    }
    .el-divider--vertical {
      width: 2px;
      height: 100%;
      margin: 0 3%;
      background-color: #cfd4f6;
    }
  }
  .dynamic-con-mobile {
    padding: 0 10px;
    height: 124px;
    .swiper-container {
      width: 100%;
      .el-row {
        border-bottom: 1px dashed #c8c8c8;
        padding-left: 10px;
        .el-col {
          font-size: 14px;
          text-align: left;
          &:not(:last-child) {
            margin-right: 3px;
          }
          &:last-child {
            text-align: right;
            min-width: 78px;
          }
        }
      }
    }
  }
}
// 金融产品
.home-fp {
  .fp-list {
    .fp-item {
      margin-bottom: 15px;
    }
  }
}
// 服务政策
.yb-con {
  // 左侧
  .yb-img {
    max-width: 600px;
    margin-right: 40px;
    position: relative;
    overflow: hidden;
    .el-image {
      height: 100%;
    }
    .yb-hot {
      width: 100%;
      height: 93px;
      position: absolute;
      left: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.8);
      p {
        padding: 0 30px;
        font-size: 24px;
        color: #fff;
        line-height: 30px;
      }
    }
  }
  // 右侧列表
  .yb-list {
    width: 100%;
    height: 100%;
    .yb-item {
      width: 100%;
      border-bottom: 1px solid #e3e3e3;
      position: relative;
      &:hover {
        &::before {
          background: #409eff;
        }
        .yb-item-info {
          .yb-item-title {
            a {
              color: #409eff;
            }
          }
        }
      }
      &::before {
        content: "";
        width: 10px;
        height: 10px;
        background: #c1c1c1;
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        left: 0;
        top: 14px;
      }
      .yb-item-info {
        width: calc(100% - 40px);
        padding: 12px 0 12px 30px;
        .yb-item-title {
          width: 80%;
        }
      }
    }
  }
}

// 新闻中心
.home-news{
  .yb-con{
    .yb-img{
      margin-right: 0;
      margin-left: 40px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
}

// 活动专场
.home-activity {
  background-color: #f3f6ff;
  .activity-tabs{
    .activity-tab{
      margin: 0 20px;
      font-size: 20px;
      font-weight: bold;
      cursor: pointer;
      &.active{
        color: #409eff;
      }
    }
  }
}
// 评估服务
.home-assess {
  height: 380px;
  background: url("../../assets/images/homg-bg1.png") no-repeat;
  background-size: cover;
  .ipbs-title h4 {
    color: #fff;
  }
  .assess-con {
    width: 80%;
    margin: 0 auto;
    padding: 55px 0 55px 68px;
    box-shadow: 0px 0px 24px 0px rgba(5, 47, 122, 0.61);
    border-radius: 10px;
    overflow: hidden;
    .assess-text {
      color: #333333;
      line-height: 24px;
      padding-right: 10%;
    }
    .assess-defaults {
      width: 168px;
      height: 60px;
      margin-right: -10px;
      border-radius: 6px;
      a {
        color: #fff;
      }
    }
  }
}
// 专利评价
.home-zlpj {
  width: 80%;
  max-width: 1156px;
  margin: 30px auto;
  position: relative;
  .zlpj-info {
    width: 56%;
    margin-right: 100px;
    .zlpj-title {
      color: #151933;
      line-height: 29px;
    }
    .zlpj-search {
      margin: 40px auto 36px;
    }
    .zlpj-desc {
      font-size: 14px;
      color: #7f8aa4;
    }
  }
}
// 平台优势
.home-ptys {
  .ptys-list {
    .el-col {
      &:first-child {
        .ptys-img {
          background-color: rgba(236, 242, 255, 1);
        }
      }
      &:nth-child(2) {
        .ptys-img {
          background-color: rgba(255, 243, 232, 1);
        }
      }
      &:last-child {
        .ptys-img {
          background-color: rgba(239, 255, 236, 1);
        }
      }
    }
    .ptys-item {
      box-shadow: 3px 5px 9px 0px rgba(99, 145, 255, 0.17);
      border-radius: 10px;
      .ptys-img {
        height: 200px;
        .el-image {
          margin: 0 auto;
        }
      }
      .ptys-info {
        padding: 20px 30px;
        min-height: 170px;
        .ptys-name {
          font-size: 24px;
        }
        .ptys-line {
          width: 70px;
          height: 7px;
          margin: 10px 0;
        }
        .ptys-desc {
          color: #151933;
          line-height: 29px;
          text-align: center;
        }
      }
    }
  }
}
// 友情链接
.home-blogroll {
  .blogroll-list {
    .blogroll-item {
      .blogroll-img {
        margin: 0 auto 20px;
        padding: 0 10px;
        height: 100px;
        border-radius: 6px;
        box-shadow: 0px 2px 9px 0px rgba(5, 20, 126, 0.31);
      }
      .blogroll-name {
        text-align: center;
      }
    }
  }
}
// 分页
/deep/ .page {
  justify-content: flex-end;
  padding: 0 35px 50px;
  .el-button {
    background-color: #ececec;
    &.is-disabled {
      background-color: #ececec;
    }
    &:hover,
    &:focus {
      background-color: #ececec;
    }
    &.active {
      background-color: #409eff;
    }
  }
}

@media (max-width: 767px) {
  .el-image {
    border-radius: 0;
  }
  .home-fp {
    margin-top: 10px;
    .fp-list {
      justify-content: space-between;
      .fp-item {
        width: 48%;
      }
    }
  }
  .yb-con {
    .yb-list {
      .yb-item {
        .yb-item-info {
          .yb-item-title {
            width: 70%;
          }
        }
      }
    }
  }

  .home-assess {
    height: 280px;
    .assess-con {
      padding: 20px;
      .assess-text {
        padding: 0;
      }
      .assess-defaults {
        height: 40px;
        margin-top: 20px;
      }
    }
  }
  .ptys-list-mobile {
    margin: 0 !important;
    .ptys-item {
      margin-bottom: 20px;
      .ptys-img {
        width: 110px;
        padding: 20px 20px 0;
      }
      .ptys-info {
        .ptys-name {
          width: 100%;
          text-align: left;
          margin-bottom: 10px;
        }
        .ptys-desc {
          line-height: 20px;
        }
      }
    }
    .el-col:nth-child(even) {
      .ptys-item .ptys-info .ptys-name {
        width: 90%;
        text-align: right;
      }
    }
  }
}
</style>