import {
  Button,
  Message,
  MessageBox,
  Container,
  Header,
  Aside,
  Main,
  Footer,
  Menu,
  MenuItem,
  Submenu,
  Image,
  Input,
  InputNumber,
  Row,
  Col,
  Divider,
  Table,
  TableColumn,
  Carousel,
  CarouselItem,
  PageHeader,
  Popover,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  RadioGroup,
  Radio,
  Checkbox,
  CheckboxGroup,
  Tag,
  Breadcrumb,
  BreadcrumbItem,
  Dialog,
  Form,
  FormItem,
  Select,
  Option,
  DatePicker,
  Popconfirm,
  Avatar,
  Empty,
  Link,
  Descriptions,
  DescriptionsItem,
  Tabs,
  TabPane,
  Upload
} from 'element-ui'
export default {
  install (Vue) {
    Vue.use(Button)
    Vue.use(Container)
    Vue.use(Header)
    Vue.use(Aside)
    Vue.use(Main)
    Vue.use(Footer)
    Vue.use(Menu)
    Vue.use(MenuItem)
    Vue.use(Submenu)
    Vue.use(Image)
    Vue.use(Input)
    Vue.use(InputNumber)
    Vue.use(Row)
    Vue.use(Col)
    Vue.use(Divider)
    Vue.use(Table)
    Vue.use(TableColumn)
    Vue.use(Carousel)
    Vue.use(CarouselItem)
    Vue.use(PageHeader)
    Vue.use(Popover)
    Vue.use(Dropdown)
    Vue.use(DropdownMenu)
    Vue.use(DropdownItem)
    Vue.use(RadioGroup)
    Vue.use(Radio)
    Vue.use(Checkbox)
    Vue.use(CheckboxGroup)
    Vue.use(Tag)
    Vue.use(Breadcrumb)
    Vue.use(BreadcrumbItem)
    Vue.use(Dialog)
    Vue.use(Form)
    Vue.use(FormItem)
    Vue.use(Select)
    Vue.use(Option)
    Vue.use(DatePicker)
    Vue.use(Popconfirm)
    Vue.use(Avatar)
    Vue.use(Empty)
    Vue.use(Link)
    Vue.use(Descriptions)
    Vue.use(DescriptionsItem)
    Vue.use(Tabs)
    Vue.use(TabPane)
    Vue.use(Upload)
    //注意，如果使用了Message组件，并使用Vue.use(Message)的话。就掉坑里了。每次刷新页面都会弹出一个Message组件
    //Vue.use(Message)

    //直接使用this.$message
    // Vue.prototype.$message = Message
    Vue.prototype.$confirm = MessageBox.confirm
  }
}