/** 浮标 */
<template>
  <!-- 在线咨询 -->
  <section v-if="!($route.matched.some(record => record.meta.hideConsulting))" class="home-consulting">
    <div v-if="$isPc" class="consulting-content">
      <ul class="consulting-list">
        <li class="consulting-item consulting-btn" @click="consultingClick"><span>在线咨询</span> </li>
        <el-popover placement="left" width="150" trigger="hover">
          <el-image :src="require('@/assets/images/guanwei.jpg')"></el-image>
          <li slot="reference" class="consulting-item">
            <span>微信订阅</span>
          </li>
        </el-popover>
        <li class="consulting-item consulting-back-top" v-if="isBackTop" @click="backTop">
          <i></i>
        </li>
      </ul>
    </div>
    <div v-else-if="!$isPc && isBackTop" class="back-top" @click="backTop">
      <i class="el-icon-top"></i>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  computed: {
    ...mapGetters(['configs'])
  },
  data () {
    return {
      scrollTop: "",
      isBackTop: false
    }
  },
  mounted () {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    // 显示回到顶部按钮
    handleScroll () {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      this.isBackTop = this.scrollTop > 200 ? true : false;
    },
    backTop () {
      cancelAnimationFrame(this.timer)
      const self = this
      self.timer = requestAnimationFrame(function fn () {
        const oTop = document.body.scrollTop || document.documentElement.scrollTop
        if (oTop > 0) {
          document.body.scrollTop = document.documentElement.scrollTop = oTop - 200
          self.timer = requestAnimationFrame(fn)
        } else {
          cancelAnimationFrame(self.timer)
        }
      })
    }
  },
  destroyed () {
    window.removeEventListener("scroll", this.handleScroll, true);
  }
};
</script>

<style scoped lang="scss">
// 在线咨询
.home-consulting {
  position: fixed;
  z-index: 1;
  right: 5%;
  bottom: 10%;
  width: 98px;
  .consulting-content {
    background: #f5f5f5;
    box-shadow: 0px 4px 4px 0px rgba(34, 23, 20, 0.25);
    border-radius: 6px;
    overflow: hidden;
    .consulting-list {
      .consulting-item {
        text-align: center;
        font-size: 14px;
        padding: 15px 0;
        border-bottom: 1px solid #d9d9d9;
        cursor: pointer;
        position: relative;
        &:hover {
          background-color: #1c60cf;
          color: #fff;
        }
        &:last-child {
          border-bottom-width: transparent;
        }
        &.consulting-back-top {
          background-color: #1c60cf;
          border-bottom: none;
          i {
            display: inline-block;
            width: 10px;
            height: 10px;
            border: 2px solid #fff;
            border-left-width: 0;
            border-bottom-width: 0;
            margin-bottom: -5px;
            transform: matrix(0.71, -0.71, 0.71, 0.71, 0, 0);
          }
        }
      }
    }
  }
}
.back-top {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #2b62b2;
  position: fixed;
  bottom: 80px;
  right: 20px;
  text-align: center;
  line-height: 47px;
  i {
    font-weight: bold;
    color: #fff;
    font-size: 24px;
  }
}
</style>
