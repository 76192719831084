import request from "@/utils/request.js"

// 网站配置
export const getConfig = () => {
  return request({
    method: 'get',
    url: '/config'
  })
}

// 企业动态
export const getDynamic = () => {
  return request({
    method: 'get',
    url: '/gettrade',
  })
}

// 专利评价
export const getPatentEvaluate = (id) => {
  return request({
    method: 'get',
    url: '/getpatentevaluate',
    headers: {
      token: true
    },
    params: { id }
  })
}

// 获取文章
export const getArticle = (data) => {
  return request({
    method: 'get',
    url: '/getarticle',
    headers: {
      isStop: true
    },
    params: data,
  })
}

// 文章详情
export const getArticleDetails = (id) => {
  return request({
    method: 'get',
    url: '/getarticleinfo',
    params: { id }
  })
}

// 最新政策
export const getNewArticle = () => {
  return request({
    method: 'get',
    url: '/getnewarticle',
  })
}

// 微信分享
export const setShare = (url) => {
  return request({
    method: 'post',
    url: '/share',
    data: {
      url
    }
  })
}