import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home/index.vue'
import store from '@/store'
import {
  setItem,
  getItem,
  removeItem
} from '@/utils/storage'
Vue.use(VueRouter)
/**
 * meta属性详解
 * 
 * loginAuth:验证登录
 * mainAuto:有二级路由时使用
 * hideHead:隐藏头部
 * isPcHead: 是否展示PC端头部
 * hideHF: 隐藏头部和尾部
 * hideNav: 为true时隐藏所有导航
 * isTabBar: 是否展示移动端tabBar
 * isWap:当前路由只在移动端显示
 * isPC:当前路由只在PC显示
 * isDetails:详情页
 * detailsTitle:移动端头部标题内容
 * hideConsulting:隐藏悬浮框
 * hideBackTop:隐藏‘回到顶部’按钮（设置hideConsulting为true，可不设置此项）
 */
const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  // 首页
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      isPcHead: true,
      isTabBar: true
    }
  },
  // 登录/注册
  {
    path: '/account/:type',
    name: 'Account',
    component: () => import('@/views/Account/index.vue'),
    meta: {
      hideNav: true,
      hideBackTop: true,
    }
  },
  // 服务中心
  {
    path: '/serve',
    name: 'Serve',
    component: () => import('@/views/Serve/index.vue'),
    redirect: '/serve/product',
    meta: {
      mainAuto: true,
      isTabBar: true,
    },
    children: [
      // 金融超市
      {
        path: 'product',
        name: 'Product',
        component: () => import('@/views/Product/index.vue'),
        meta: {
          hideHead: true,
        }
      },
      // 合作机构
      {
        path: 'partners',
        name: 'Partners',
        component: () => import('@/views/Partners/index.vue'),
        meta: {
          isWap: true,
          hideHead: true,
        }
      },
    ]
  },
  // 金融超市-详情
  {
    path: '/product_details',
    name: 'ProductDetails',
    component: () => import('@/views/Product/details.vue'),
    meta: {
      activeMenu: '/serve/product',
      detailsTitle: '产品详情'
    }
  },
  // 金融超市-金融产品申请
  {
    path: '/product_form',
    name: 'ProductForm',
    component: () => import('@/views/Product/Pform.vue'),
    meta: {
      isWap: true,
      detailsTitle: '申请金融产品'
    }
  },
  // 政策
  {
    path: '/policy',
    name: 'Policy',
    component: () => import('@/views/Policy/index.vue'),
    meta: {
      detailsTitle: '政策服务',
    }
  },
  // 政策详情
  {
    path: '/policy_details',
    name: 'PolicyDetails',
    component: () => import('@/views/Policy/details.vue'),
    meta: {
      activeMenu: '/policy',
      detailsTitle: '文章详情'
    }
  },
  // 新闻中心
  {
    path: '/news',
    name: 'News',
    component: () => import('@/views/News/index.vue'),
    meta: {
      detailsTitle: '新闻中心'
    }
  },
  // 新闻详情
  {
    path: '/news_details',
    name: 'NewsDetails',
    component: () => import('@/views/News/details.vue'),
    meta: {
      activeMenu: '/news',
      detailsTitle: '新闻详情'
    }
  },
  // 活动专场
  {
    path: '/activity',
    name: 'Activity',
    component: () => import('@/views/Activity/index.vue'),
    redirect: '/activity/events',
    meta: {
      mainAuto: true,
      detailsTitle: '活动专场',
    },
    children: [
      {
        path: 'events',
        name: 'ActivityEvents',
        component: () => import('@/views/Activity/events.vue'),
        meta: {
          detailsTitle: '活动通知'
        }
      },
      {
        path: 'news',
        name: 'ActivityNews',
        component: () => import('@/views/Activity/news.vue'),
        meta: {
          detailsTitle: '活动新闻'
        }
      },
    ]
  },
  // 活动专场-列表
  {
    path: '/activity_list',
    name: 'ActivityList',
    component: () => import('@/views/Activity/list.vue'),
    meta: {
      activeMenu: '/activity/events',
      detailsTitle: '活动列表'
    }
  },
  // 活动通知-详情
  {
    path: '/activity_details',
    name: 'ActivityDetails',
    component: () => import('@/views/Activity/details.vue'),
    meta: {
      activeMenu: '/activity/events',
      detailsTitle: '活动详情'
    }
  },
  // 活动新闻-详情
  {
    path: '/activity_news_details',
    name: 'ActivityNewsDetails',
    component: () => import('@/views/Activity/news_details.vue'),
    meta: {
      activeMenu: '/activity/news',
      detailsTitle: '活动新闻详情'
    }
  },

  // 数据服务
  {
    path: '/data_service',
    name: 'DataService',
    component: () => import('@/views/DataService/index.vue'),
    meta: {
      isPC: true,
      hideHF: true,
      hideConsulting: true,
      loginAuth: true
    },
    redirect: '/data_service',
    children: [
      {
        path: '/',
        name: 'DataHome',
        component: () => import('@/views/DataService/home.vue'),
      },
      // {
      //   path: 'details',
      //   name: 'DataDetails',
      //   component: () => import('@/views/DataService/details.vue'),
      // },
      {
        path: 'area',
        name: 'DataArea',
        component: () => import('@/views/DataService/area.vue'),
      }
    ]
  },

  // 知识产权供需
  {
    path: '/trading',
    name: 'Trading',
    component: () => import('@/views/Trading/index.vue'),
    meta: {
      isPC: true,
    },
    children: [
      {
        path: 'patent',
        name: 'Patent',
        component: () => import('@/views/Trading/patent.vue'),
      },
      {
        path: 'trademark',
        name: 'Trademark',
        component: () => import('@/views/Trading/trademark.vue'),
      },
      {
        path: 'license',
        name: 'License',
        component: () => import('@/views/Trading/license.vue'),
      },
      {
        path: 'license_details',
        name: 'LicenseDetails',
        component: () => import('@/views/Trading/details.vue'),
        meta: {
          activeMenu: '/trading/license',
        }
      },
      {
        path: 'patent_details',
        name: 'PatentDetails',
        component: () => import('@/views/Trading/details.vue'),
        meta: {
          activeMenu: '/trading/patent',
        }
      },
      {
        path: 'trademark_details',
        name: 'TrademarkDetails',
        component: () => import('@/views/Trading/details.vue'),
        meta: {
          activeMenu: '/trading/trademark',
        }
      },
      {
        path: 'patent/pack_details',
        name: 'PatentPackDetails',
        component: () => import('@/views/Trading/pack_details.vue'),
        meta: {
          activeMenu: '/trading/patent',
        }
      },
      {
        path: 'trademark/pack_details',
        name: 'TrademarkPackDetails',
        component: () => import('@/views/Trading/pack_details.vue'),
        meta: {
          activeMenu: '/trading/trademark',
        }
      }
    ]
  },

  // 评估系统
  {
    path: '/assessment',
    name: 'Assessment',
    component: () => import('@/views/Assessment/index.vue'),
    meta: {
      detailsTitle: '中知云评估系统'
    }
  },

  // 线上咨询
  {
    path: '/counsel',
    name: 'Counsel',
    component: () => import('@/views/Counsel/index.vue'),
    meta: {
      isWap: true,
      hideHead: true,
      hideBackTop: true,
      isTabBar: true
    }
  },
  // 个人中心
  {
    path: '/my',
    name: 'My',
    component: () => import('@/views/My/index.vue'),
    meta: {
      loginAuth: true
    },
    redirect: to => {
      if (Vue.prototype.$isPc) {
        return '/my/order/finance'
      } else {
        return '/my/info'
      }
    },
    children: [
      {
        path: 'info',
        name: 'Info',
        component: () => import('@/views/My/info.vue'),
        meta: {
          isWap: true,
          hideHead: true,
          isTabBar: true
        }
      },
      {
        path: 'order/finance',
        name: 'FinanceOrder',
        component: () => import('@/views/My/order.vue'),
        meta: {
          detailsTitle: '我的订单'
        }
      },
      // 知识产权交易订单
      {
        path: 'order/trading',
        name: 'TradingOrder',
        component: () => import('@/views/My/trading/trading_order.vue'),
        meta: {
          isPC: true,
        }
      },
      // 卖出管理
      {
        path: 'trading/sell',
        name: 'MyTradingSell',
        component: () => import('@/views/My/trading/sell.vue'),
        meta: {
          isPC: true,
        }
      },
      // 发布卖品
      {
        path: 'trading/product',
        name: 'MyTradingSellForm',
        component: () => import('@/views/My/trading/sell_form.vue'),
        meta: {
          isPC: true,
          activeMenu: '/my/trading/sell'
        }
      },
      // 买入管理
      {
        path: 'trading/buy',
        name: 'MyTradingBuy',
        component: () => import('@/views/My/trading/buy.vue'),
        meta: {
          isPC: true,
        }
      },
      // 求购表单
      {
        path: 'trading/buy_form',
        name: 'MyTradingBuyForm',
        component: () => import('@/views/My/trading/buy_form.vue'),
        meta: {
          isPC: true,
          activeMenu: '/my/trading/buy'
        }
      },
    ]
  }

]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  },
})

router.beforeEach((to, from, next) => {
  // 设置网站搜索关键字
  let head = document.getElementsByTagName('head');
  let meta = document.createElement('meta');
  /** keywords-网页关键字 description-网站内容描述 */
  document.querySelector('meta[name="keywords"]').setAttribute('content', '知识产权证券化,知识产权资产证券化,知识产权证券化产品,知识产权证券化融资,知识产权证券化服务,知识产权证券化中金浩,专利证券化融资,专利资产证券化,商标证券化,中技所知识产权证券化,知识产权证券化交易,国内知识产权证券化,技术产权证券化,知识产权ABS,版权证券化,知识产权金融研究院')
  document.querySelector('meta[name="description"]').setAttribute('content', '')
  meta.content = to.meta.content;
  head[0].appendChild(meta)

  var u = navigator.userAgent;
  var IOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  // 修复iOS版微信分享HTML5 History兼容性问题
  if (IOS && !getItem('isIOS')) {
    setItem("isIOS", true)
    setItem("firstUrl", window.location.href.split('#')[0])
  }

  // 是否登录
  let isLogin = store.getters.userToken
  let isScress = store.getters.userInfo && store.getters.userInfo.is_scress == 2
  // 未登录状态；当路由到nextRoute指定页时，跳转至login
  if (Vue.prototype.$isPc && to.matched.some(record => record.meta.loginAuth)) {
    if (!isLogin) {
      next({
        path: '/account/login',
        query: {
          redirect: to.fullPath
        }
      })
    } else {
      // 判断是否是进入数据大屏页面
      if (to.matched.some(record => record.name == 'DataService')) {
        // 判断是否有权限进入
        if (isScress) {
          next()
        } else {
          next({
            path: '/',
          })
        }
      } else {
        next()
      }
    }
  }
  // 已登录状态；当路由到登录/注册页时，进行跳转
  if (isLogin && (to.params.type == 'login' || to.params.type == 'register')) {
    removeItem('router_url')
    next({
      path: from.fullPath
    })
  }

  if ((Vue.prototype.$isPc && to.meta.isWap) || (!Vue.prototype.$isPc && to.matched.some(record => record.meta.isPC))) {
    next('/')
  }
  next()
})

// 避免重复点击路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router